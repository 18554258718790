import React from "react";

function PopupAIError({error}){

  return(
    <div>
      <p>{error}</p>
    </div>
  )
}


export default PopupAIError